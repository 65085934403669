import * as React from 'react';
import { Page } from '@alexdisler/components-landing';
import { TextBlock } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems } from '../../providers/data';
import s from './about.module.css';

export class AboutPageProps {
  // in
  // out
}

export class AboutPage extends React.Component<AboutPageProps> {

  public render() {
    return (
      <Page
        title="RealNumbers Ltd - About"
        copyright="RealNumbers, Ltd"
        name={name}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        {this.renderPageContent()}
      </Page>
    );
  }

  private renderPageContent = () => {
    return (
      <TextBlock>
        <div className={s.container}>
          <h1>About</h1>
          <p>
            RealNumbers Ltd is a boutique firm which was established in 2016.
            The firm provides a full range of end to end financial services and solutions to VCs, start-ups and other small to medium size businesses.
          </p>
          <p>
            RealNumbers brings to the table a wealth of experience providing CFO services in the high-tech industry
            - including establishing overseas companies, supporting entrepreneurs, fundraising,
            financial due-diligence, writing investment agreements, financial plans (long-term budget), esop plans and more.
          </p>
          <p>
            The founder, Eran Dor, is experienced in managing the financial aspects of many board of directors’ meetings, plans and actuals, cash flow, engagements,
            financial business overviews, and had accompanied board members in decision-making of financial matters.
            Eran can not only provide bookkeeping and payroll services, but also tax services, and an exposure to the VC world, as well as assistance in fund raising,
            buyouts, mergers and liquidations.
            Eran is experienced in dozens of funds raising processes.
          </p>
          <p>
            Prior to establishing RealNumbers, Eran served as a CFO in VCs and in start-ups in the cyber security and health industries.
          </p>
        </div>
      </TextBlock>
    );
  }

}