import * as React from 'react';
import { TextBlock } from '@alexdisler/components-landing';
import { Link } from 'react-router-dom';
import { Page } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems  } from '../../providers/data';
import s from './error-404.module.css';

interface Props {
  // in
  // out
}

export class Error404Page extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="Error 404"
        copyright="RealNumbers, Ltd"
        name={name}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        <TextBlock>
          <div className={s.container}>
            <h1>Page Not Found</h1>
            <p>
              Please try again or go back to the <Link to="/">home page</Link>
            </p>
          </div>
        </TextBlock>
      </Page>
    );
  }

}