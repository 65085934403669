import * as React from 'react';
import { Page } from '@alexdisler/components-landing';
import { TextBlock } from '@alexdisler/components-landing';
import s from './contact.module.css';
import { name, headerMenuItems, footerMenuItems  } from '../../providers/data';

export class Props {
  // in
  // out
}

export class ContactPage extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="RealNumbers Ltd - Contact"
        copyright="RealNumbers, Ltd"
        name={name}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        {this.renderPageContent()}
      </Page>
    );
  }

  private renderPageContent = () => {
    return (
      <TextBlock>
        <div className={s.container}>
          <h1>Contact</h1>
          <p>
            We are always available to discuss the services that we can provide you with.<br/>
            <br />
            Please contact us at <a href="mailto:eran@realnumbers.co.il">eran@realnumbers.co.il</a>.<br/>
            <br />
            Looking forward to hearing from you.<br/>
          </p>
        </div>
      </TextBlock>
    );
  }

}