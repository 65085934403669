import * as React from 'react';
import { Intro } from '@alexdisler/components-landing';
import { Page } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems  } from '../../providers/data';
import { Centered } from '@alexdisler/components-landing';
import s from './home-page.module.css';
import { Link } from 'react-router-dom';

interface Props {
  // in
  // out
}

export class HomePage extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="RealNumbers Ltd - Financial Services for Startups and Small Businesses"
        copyright="RealNumbers, Ltd"
        transparentMenu={true}
        name={name}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        <Intro>
          <Centered style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={s.mainText}>Strategic Financial Services</div>
            <div className={s.subText}>
              RealNumbers Ltd is a boutique firm which was established in 2016.
              The firm provides a full range of end to end financial services and solutions to VCs, start-ups and other small to medium size businesses.
              <br/>
              <Link to="/about" className={s.readMore}>Read More</Link>
            </div>
          </Centered>
          <div className={s.bgImage}>
            <img src="/bg.jpg" />
          </div>
        </Intro>
      </Page>
    );
  }

}