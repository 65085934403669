import * as React from 'react';
import { Page } from '@alexdisler/components-landing';
import { TextBlock } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems } from '../../providers/data';
import s from './team.module.css';

interface Props {
  // in
  // out
}

export class TeamPage extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="RealNumbers Ltd - Team"
        copyright="RealNumbers, Ltd"
        name={name}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        {this.renderPageContent()}
      </Page>
    );
  }

  private renderPageContent = () => {
    return (
      <TextBlock>
        <div className={s.container}>
          <h1>Team</h1>
          {this.renderProfile('/erandor.jpg', 'Eran Dor, CPA', <>
            Eran Dor is experienced in managing the financial aspects of many board of directors’ meetings, plans and actuals, cash flow, engagements,
            financial business overviews, and had accompanied board members in decision-making of financial matters.
            Eran can not only provide bookkeeping and payroll services, but also tax services, and an exposure to the VC world, as well as assistance in fund raising,
            buyouts, mergers and liquidations.
            Eran is experienced in dozens of funds raising processes.
            <br/>
            <br/>
            Prior to establishing RealNumbers, Eran served as a CFO in VCs and in start-ups in the cyber security and health industries.
            <br/>
            <br/>
            <b>2010-2011</b> - MBA, specializing in Finance - <b><i>Tel Aviv University</i></b><br/>
            <b>2001-2005</b> - BA, Economics and Accounting - <b><i>Bar-Ilan University</i></b>
            <span className={s.eduNote}> * graduated with honors</span>
          </>)}
          {this.renderProfile('', 'Dorit Blit', <>Bookkeeper</>)}
          {this.renderProfile('', 'Miri Cohen Hefetz', <>Controller and Tax matters</>)}
          {this.renderProfile('', 'Ori Has', <>Counsel and CPA</>)}
        </div>
      </TextBlock>
    );
  }

  private renderProfile = (pic: string, title: string, description: React.ReactElement) => {
    const picStyle = (pic ? { backgroundImage: `url("${pic}")` } : {});
    return (
      <div className={s.profile}>
        <div className={s.profilePicContainer} style={picStyle}>
          <div className={s.profileTitle}>
            {title}
          </div>
        </div>
        <div className={s.profileDescription}>
          {description}
        </div>
      </div>
    );
  };

}