import * as React from 'react';
import { Page } from '@alexdisler/components-landing';
import { TextBlock } from '@alexdisler/components-landing';
import { name, headerMenuItems, footerMenuItems  } from '../../providers/data';
import s from './customers.module.css';

interface Props {
  // in
  // out
}

export class CustomersPage extends React.Component<Props> {

  public render() {
    return (
      <Page
        title="RealNumbers Ltd - Customers"
        copyright="RealNumbers, Ltd"
        name={name}
        headerMenuItems={headerMenuItems}
        footerMenuItems={footerMenuItems}
      >
        {this.renderPageContent()}
      </Page>
    );
  }

  private renderPageContent = () => {
    return (
      <TextBlock>
        <div className={s.container}>
          <h1>Customers</h1>
          <p>
            Currently we serve start-ups and a VCs in many fields:<br/>
            <b>-</b> IoT<br/>
            <b>-</b> Cyber Security<br/>
            <b>-</b> Health<br/>
            <b>-</b> Digital Health<br/>
            <b>-</b> 3-D printing<br/>
            <b>-</b> Travel<br/>
            <b>-</b> AI<br/>
            and others<br/>
          </p>
        </div>
      </TextBlock>
    );
  }

}